/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Card,
  CardContent,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { ArrowLeft, ArrowRight } from 'react-feather';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import thLocale from 'date-fns/locale/th';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error, NotAuthorized } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
// import ProductGraph from '../../components/Graph/ProductGraph';
// import ProductDonut from '../../components/Graph/ProductDonut';
// import ProductGraphProfit from '../../components/Graph/ProductGraphProfit';
import StatusCards from '../../components/Graph/StatusCards';
import StatusCardsOnline from '../../components/Graph/StatusCardsOnline';
import TableStatus from '../../components/Graph/TableStatus';
import TableStatusOnline from '../../components/Graph/TableStatusOnline';

import accessSubModule from '../../utils/functions/accessSubModule';

const MainDashboard = ({ title, subtitle }) => {
  const module = 'DASHBOARD';
  const subModule = 'MAIN';
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);

  const productTransaction = useSelector((state) => state.productTransaction);
  const productTransactionExport = useSelector(
    (state) => state.productTransactionExport,
  );
  const product = useSelector((state) => state.product);
  const customer = useSelector((state) => state.customer);

  const name = '';

  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));
  const [periodText, setPeriodText] = useState('ออก');
  const [size] = useState(5);

  const transactionType = 'IMPORT';
  const transactionType1 = 'ALL';
  const DashBoardPage = true;

  console.log('data of productTransaction', productTransaction);
  console.log('data of productTransactionExport', productTransactionExport);
  console.log('data of product', product);
  console.log('data of customer', customer);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        await dispatch(actions.customerAll({ name }));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchMe = async () => {
      try {
        await dispatch(actions.meGet(''));
      } catch (error) {
        console.error(error);
      }
    };
    fetchCustomer();
    fetchMe();

    return () => {};
  }, []);

  useEffect(async () => {
    setLoading(true);
    await dispatch(
      actions.productTransactionAll({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        transactionType,
      }),
    );

    await dispatch(actions.productAllWithLightWeight(''));

    setLoading(false);
    return () => {};
  }, [startDate, endDate]);

  useEffect(async () => {
    setLoading(true);

    await dispatch(
      actions.productTransactionExportAll({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      }),
    );

    setLoading(false);
    return () => {};
  }, [startDate, endDate]);

  const minusOneMonth = () => {
    const previousMonth = dayjs(startDate)
      .subtract(1, 'month')
      .startOf('month');
    const endOfPreviousMonth = dayjs(startDate)
      .subtract(1, 'month')
      .endOf('month');
    setStartDate(previousMonth);
    setEndDate(endOfPreviousMonth);
  };

  const addOneMonth = () => {
    const previousMonth = dayjs(startDate).add(1, 'month').startOf('month');
    const endOfPreviousMonth = dayjs(startDate).add(1, 'month').endOf('month');
    setStartDate(previousMonth);
    setEndDate(endOfPreviousMonth);
  };

  const renderPeriod = () => (
    <>
      <Card className="mb-2">
        <CardContent>
          {/* ***************** */}
          <div className="w-full my-2 flex justify-between gap-1">
            <Button
              variant="outlined"
              startIcon={<ArrowLeft />}
              onClick={minusOneMonth}
            >
              กลับ 1 เดือน
            </Button>
            <div className="flex justify-center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    label="วันที่เริ่มต้น"
                    defaultValue={dayjs()}
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                  />
                  <DatePicker
                    label="วันที่สิ้นสุด"
                    defaultValue={dayjs()}
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <Button
              variant="outlined"
              endIcon={<ArrowRight />}
              onClick={addOneMonth}
            >
              เพิ่ม 1 เดือน
            </Button>
          </div>
        </CardContent>
      </Card>
    </>
  );

  const renderAllStatus = () => (
    <StatusCards
      startDate={startDate}
      endDate={endDate}
      loading={loading}
      product={product?.rows}
      productTransaction={productTransaction}
      productTransactionExport={productTransactionExport}
      customer={customer}
      setLoading={setLoading}
      periodText={periodText}
    />
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (
    me.isLoading ||
    customer.isLoading ||
    product.isLoading ||
    productTransaction.isLoading ||
    productTransactionExport.isLoading
  ) {
    return <Loading />;
  }
  if (
    !me.isLoading &&
    me.isCompleted &&
    !customer.isLoading &&
    customer.isCompleted &&
    !product.isLoading &&
    product.isCompleted &&
    !productTransactionExport.isLoading &&
    productTransactionExport.isCompleted &&
    !productTransaction.isLoading &&
    productTransaction.isCompleted
  ) {
    if (accessSubModule(me?.userData?.access, module, subModule)) {
      return (
        <div>
          {/* <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={!loading}
            onClose={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop> */}
          <div className="flex justify-between">
            <div>{renderTitle()}</div>
          </div>
          {renderPeriod()}
          {renderAllStatus()}

          <div className="grid  md:grid-cols-2  gap-x-2 gap-y-2 pb-2">
            <TableStatus
              product={product?.rows}
              productTransaction={productTransaction}
              productTransactionExport={productTransactionExport}
              customer={customer?.rows}
              startDate={startDate}
              endDate={endDate}
              loading={loading}
              periodText={periodText}
            />
          </div>
        </div>
      );
    }
    return <NotAuthorized />;
  }
  return <Error message={me?.message} />;
};

MainDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MainDashboard.defaultProps = {
  title: '',
  subtitle: '',
};

export default MainDashboard;
