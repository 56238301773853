import { Card, Skeleton } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
// import dayjs from 'dayjs';
// import Loading from '../Loading';

export function StatusCards({
  product,
  productTransaction,
  customer,
  loading,
  periodText,
  productTransactionExport,
}) {
  const ReserveOrderCount = _.filter(
    productTransactionExport?.rows,
    (each) => each?.reserve_order,
  );

  console.log('ReserveOrderCount', ReserveOrderCount);
  // console.log('NewProductIn', NewProductIn);

  // console.log('countQuantity', countQuantity);

  if (!loading) {
    return (
      <div className="grid  md:grid-cols-2  gap-x-2 gap-y-2 pb-2">
        <div>
          <Card className="p-6">
            <div>
              <div className="pb-2 text-xl">จำนวนรายการนำเข้า</div>
              <div className="flex">
                <i className={'fas fa-coins fa-2x'} />
                <h1 className="pl-2 my-auto text-2xl">
                  {productTransaction?.total || 0}
                </h1>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Card className="p-6">
            <div>
              <div className="pb-2 text-xl">จำนวนรายการส่งออก</div>
              <div className="flex">
                <i className={'fas fa-coins fa-2x'} />
                <h1 className="pl-2 my-auto text-2xl">
                  {productTransactionExport?.total}
                </h1>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Card className="p-6">
            <div>
              <div className="pb-2 text-xl">จำนวนลูกค้าทั้งหมด</div>
              <div className="flex">
                <i className={'far fa-address-card fa-2x'} />
                <h1 className="pl-2 my-auto text-2xl">
                  {customer?.rows?.length || 0}
                </h1>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Card className="p-6">
            <div>
              <div className="pb-2 text-xl">จำนวนรายการจองสินค้า</div>
              <div className="flex">
                <i className={'far fa-clipboard fa-2x'} />
                <h1 className="pl-2 my-auto text-2xl">
                  {ReserveOrderCount?.length || 0}
                </h1>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return (
    <div className="grid  md:grid-cols-2  gap-x-2 gap-y-2 pb-2">
      <div>
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนรายการนำเข้า</div>
            <div className="flex">
              <i className={'fas fa-coins fa-2x'} />
              <div className="pl-2 my-auto">
                <Skeleton variant="rectangular" width={210} height={20} />
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนรายการส่งออก</div>
            <div className="flex">
              <i className={'fas fa-box-open fa-2x'} />
              <div className="pl-2 my-auto">
                <Skeleton variant="rectangular" width={210} height={20} />
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนลูกค้าทั้งหมด</div>
            <div className="flex">
              <i className={'far fa-address-card fa-2x'} />
              <h1 className="pl-2 my-auto text-2xl">
                {customer?.rows?.length}
              </h1>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนรายการจองสินค้า</div>
            <div className="flex">
              <i className={'far fa-clipboard fa-2x'} />
              <div className="pl-2 my-auto">
                <Skeleton variant="rectangular" width={210} height={20} />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

StatusCards.propTypes = {
  product: PropTypes.array,
  productTransaction: PropTypes.object,
  productTransactionExport: PropTypes.object,
  title: PropTypes.string,
  count: PropTypes.number,
  icon: PropTypes.string,
  periodText: PropTypes.string,
  customer: PropTypes.object,
  startDate: PropTypes.text,
  endDate: PropTypes.text,
  loading: PropTypes.bool,
};

StatusCards.defaultProps = {
  product: null,
  productTransaction: null,
  productTransactionExport: null,
  title: null,
  count: null,
  icon: null,
  customer: null,
  startDate: null,
  endDate: null,
  loading: null,
};

export default StatusCards;
